var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modalContent",staticClass:"modal fade modal-irispro",attrs:{"tabindex":"-1","role":"dialog","data-backdrop":"static","aria-hidden":"true","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-dialog",staticStyle:{"max-width":"1048px"},attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"}),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.hide}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.isAdd ? "Add Product to Dealer" : "Update Product")+" ")]),_c('div',{staticClass:"modal-form"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"fm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticStyle:{"width":"350px"}},[_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"model","name":"model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.model),expression:"value.model"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Model"},domProps:{"value":(_vm.value.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.value.productId === 0)?_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"name":"product qty","rules":"required|numeric|min_value:1|max_value:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.value.productQtyUi),expression:"value.productQtyUi",modifiers:{"number":true}}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Product Qty"},domProps:{"value":(_vm.value.productQtyUi)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "productQtyUi", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"invoiceno","name":"invoice no.","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.invoiceNo),expression:"value.invoiceNo"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Invoice No."},domProps:{"value":(_vm.value.invoiceNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "invoiceNo", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"vid":"deliveryDateJs","name":"delivery date","rules":"required|date_format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.deliveryDateUi),expression:"value.deliveryDateUi"}],ref:"inputDeliveryDate",class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Delivery Date","autocomplete":"off"},domProps:{"value":(_vm.value.deliveryDateUi)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "deliveryDateUi", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticStyle:{"width":"350px","margin-left":"25px"}},[(_vm.value.productId === 0)?[_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"warrantysealqty","name":"warranty seal qty","rules":_vm.warrantySealQtyValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.value.warrantySealQty),expression:"value.warrantySealQty",modifiers:{"number":true}}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Warranty Seal Qty"},domProps:{"value":(_vm.value.warrantySealQty)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "warrantySealQty", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"runno","name":"run no. from","rules":_vm.warrantySealRunNoValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',[_vm._v("Run No. From")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.value.runNo),expression:"value.runNo",modifiers:{"number":true}}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Run No. From"},domProps:{"value":(_vm.value.runNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "runNo", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('label',[_vm._v("Run No. To")]),_c('input',{class:{
                          'form-control': true,
                        },attrs:{"type":"text","placeholder":"Run No. To","readonly":""},domProps:{"value":!!_vm.value.warrantySealQty && !!_vm.value.runNo
                            ? _vm.value.runNo + _vm.value.warrantySealQty - 1
                            : ''}})])]:[_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"serialno","name":"serial no.","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.serialNo),expression:"value.serialNo"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Serial No."},domProps:{"value":(_vm.value.serialNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "serialNo", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"totalsqft","name":"total sq. ft.","rules":"required|double:1|min_value:0.1","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('auto-decimal-input',{ref:"sqftAutoDecimal1",class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Total Sq. Ft."},model:{value:(_vm.value.totalSqFt),callback:function ($$v) {_vm.$set(_vm.value, "totalSqFt", $$v)},expression:"value.totalSqFt"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"validYears","name":"warranty valid years","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.value.validYears),expression:"value.validYears",modifiers:{"number":true}}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Warranty Years"},domProps:{"value":(_vm.value.validYears)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "validYears", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]],2)]),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.products.length > 0),expression:"value.products.length > 0"}],staticClass:"form-hr-red"}),_vm._l((_vm.value.products),function(product,index){return _c('div',{key:index,staticClass:"d-flex justify-content-center",class:{
                  'mb-3': index !== _vm.value.products.length - 1,
                }},[_c('div',{staticStyle:{"width":"225px"}},[_c('ValidationProvider',{attrs:{"vid":("serialNo" + index),"name":"serial no.","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.products[index].serialNo),expression:"value.products[index].serialNo"}],class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Serial No."},domProps:{"value":(_vm.value.products[index].serialNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.products[index], "serialNo", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"width":"225px","margin-left":"25px"}},[_c('ValidationProvider',{attrs:{"vid":("totalSqFt" + index),"name":"total sq. ft.","rules":"required|double:1|min_value:0.1","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('auto-decimal-input',{ref:"sqftAutoDecimal2",refInFor:true,class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Total Sq. Ft."},model:{value:(_vm.value.products[index].totalSqFt),callback:function ($$v) {_vm.$set(_vm.value.products[index], "totalSqFt", $$v)},expression:"value.products[index].totalSqFt"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"width":"225px","margin-left":"25px"}},[_c('ValidationProvider',{attrs:{"vid":("validYears" + index),"name":"warranty valid years","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.value.products[index].validYears),expression:"value.products[index].validYears",modifiers:{"number":true}}],class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Warranty Years"},domProps:{"value":(_vm.value.products[index].validYears)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.products[index], "validYears", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])}),_c('button',{ref:"hiddenSubmit",staticClass:"invisible",attrs:{"type":"submit"}})],2)]}}])})],1),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.errorMessage))])])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-button d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-iris",attrs:{"disabled":_vm.value.isSubmitting},on:{"click":function($event){return _vm.$refs.hiddenSubmit.click()}}},[_vm._v(" "+_vm._s(_vm.isAdd ? "Add Product" : "Update Product")+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }