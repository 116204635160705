




import Vue from 'vue';
export default Vue.extend({
  'props': {
    'value': {
      'type': [Number, String]
    },
    'decimal': {
      'type': Number,
      'default': 1
    }
  },
  mounted () {
    if (!isNaN(parseFloat(this.value + '')) && parseFloat(this.value + '') !== 0) {
      this.change();
    }
  },
  'methods': {
    change () {
      if (!isNaN(parseFloat(this.value + ''))) {
        const decimalValue = parseFloat(this.value + '').toFixed(this.decimal);
        this.$emit('input', decimalValue);
      } else {
        this.$emit('input', this.value);
      }
    }
  }
});
