




































































































































































































































































































































/* global $ */
import { VeeValidateObserverRef } from '@/types/libraries/VeeValidateObserverRef';
import { ApiValidationErrorResponse } from '@/types/api/Response';
import Vue from 'vue';
import mixinsVue from './mixins.vue';
import { ProductForm } from '@/types/api/Product';
import AutoDecimalInput from '@/components/helpers/AutoDecimalInput.vue';
export default Vue.extend({
  'name': 'ModalAddWarrantySealToDealer',
  'mixins': [mixinsVue],
  'components': {
    AutoDecimalInput
  },
  'props': {
    'value': {
      'type': Object,
      'required': true
    },
    'errorMessage': {
      'type': String,
      'default': ''
    }
  },
  data () {
    return {
      'isAdd': true
    };
  },
  'computed': {
    productQty () {
      return this.value.productQtyUi;
    },
    warrantySealQtyValidation () {
      return this.value.runNo !== null && this.value.runNo !== ''
        ? 'required|numeric|min_value:0'
        : '';
    },
    warrantySealRunNoValidation () {
      return this.value.warrantySealQty !== null &&
        this.value.warrantySealQty !== ''
        ? 'required|numeric|min_value:0'
        : '';
    }
  },
  'watch': {
    productQty (val) {
      if (!val || val > 20) {
        this.value.products = [];
      } else {
        if (val > this.value.products.length) {
          const lacking = val - this.value.products.length;

          for (let i = 0; i < lacking; i++) {
            (this.value.products as Array<ProductForm>).push({
              'serialNo': '',
              'totalSqFt': null,
              'validYears': null
            });
          }
        } else if (val < this.value.products.length) {
          const extra = (this.value.products.length - val) as number;
          (this.value.products as Array<ProductForm>).splice(
            this.value.products.length - 1 - extra,
            extra
          );
        }
      }
    }
  },
  mounted () {
    // @ts-expect-error: Ignore error explicitly.
    $(this.$refs.inputDeliveryDate).daterangepicker({
      'singleDatePicker': true,
      'autoApply': true,
      'locale': {
        'format': 'DD/MM/YYYY'
      }
    });

    $(this.$refs.inputDeliveryDate).on(
      'apply.daterangepicker',
      (ev, picker) => {
        this.value.deliveryDateUi = picker.startDate.format('DD/MM/YYYY');
      }
    );
  },
  'methods': {
    onSubmit () {
      this.$emit('submit');
    },
    update () {
      this.isAdd = false;
      (this.$refs.form as any).reset();

      this.$nextTick(() => {
        if (this.$refs.sqftAutoDecimal1) {
          (this.$refs.sqftAutoDecimal1 as any).change();
        }

        if (this.$refs.sqftAutoDecimal2) {
          (this.$refs.sqftAutoDecimal2 as any).change();
        }
      });

      (this as any).show();
    },
    create () {
      this.isAdd = true;
      (this.$refs.form as any).reset();

      this.$nextTick(() => {
        if (this.$refs.sqftAutoDecimal1) {
          (this.$refs.sqftAutoDecimal1 as any).change();
        }

        if (this.$refs.sqftAutoDecimal2) {
          (this.$refs.sqftAutoDecimal2 as any).change();
        }
      });

      (this as any).show();
    },
    setErrors (errors: ApiValidationErrorResponse) {
      (this.$refs.form as VeeValidateObserverRef).setErrors(errors);
    }
  }
});
