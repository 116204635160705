




























































import DealerDetail from '@/components/DealerDetail.vue';
import DealerDualMenu from '@/components/DealerDualMenu.vue';
import SortableTable from '@/components/SortableTable.vue';
import ModalAddProduct from '@/components/modals/AddProduct.vue';
import ModalConfirmation from '@/components/modals/Confirmation.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import {
  createCreateProductRequest,
  CreateProductRequest,
  ProductForm,
  ProductListDetailResponse,
  ProductListRequest,
  ProductListResponse,
  ProductResponse,
  resetCreateProductRequest,
  updateCreateProductRequest
} from '@/types/api/Product';
import {
  initPagination,
  Pagination,
  updatePagination
} from '@/types/Pagination';

import {
  initTableSortAndFilter,
  updateTableSort,
  TableSortAndFilter
} from '@/types/TableSortAndFilter';
import {
  cleanupFilterRequest,
  createFilterRequest
} from '@/types/api/FilterRequest';
import debounce from 'debounce';
import Vue from 'vue';
import { ApiResponse } from '@/types/api/Response';
import { AxiosError } from 'axios';
const deleteProductConfirmationTitle = 'Delete Confirmation';
const deleteProductConfirmationMessage = '<p>Are you sure you want to delete this product?</p><p>This action cannot be undone!</p>';
export default Vue.extend({
  'name': 'DealerStockTrackingList',
  'components': {
    DealerDetail,
    DealerDualMenu,
    SortableTable,
    ModalAddProduct,
    ModalConfirmation
  },
  data () {
    const form = createCreateProductRequest();
    const pagination = initPagination();
    const tableFilter = initTableSortAndFilter();
    return {
      form,
      'formErrorMessage': '',
      pagination,
      tableFilter,
      'tableHeader': [
        {
          'key': 'product.model',
          'display': 'Model',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.serialNo',
          'display': 'Serial No.',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.totalSqFt',
          'display': 'Total Sq. Ft.',
          'width': 160,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'validYears',
          'display': 'Warranty Years',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.invoiceNo',
          'display': 'Invoice No.',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.createdAt',
          'display': 'Delivery Date',
          'width': 190,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'regSqFt',
          'display': 'Registered Sq. Ft.',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'unregSqFt',
          'display': 'Unregistered Sq. Ft.',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.createdBy.name',
          'display': 'Created By',
          'width': 200,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': '',
          'display': '',
          'width': 150,
          'cssClass': '',
          'isSortable': false,
          'sortOrder': 0,
          'isEmpty': true
        }
      ] as Array<SortableTableHeader>,
      'tableDataConfigs': [
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': [],
          'valueFn': (value) => { return value.toFixed(1); }
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'isDate': true,
          'dateTimeFormat': 'DD MMM YYYY'
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': [],
          'valueFn': (value) => { return value.toFixed(1); }
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': [],
          'valueFn': (value) => { return value.toFixed(1); }
        },
        {
          'cssClass': 'text-center text-uppercase',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-nowrap',
          'hasDisplay': false,
          'buttons': [
            {
              'cssClass': 'btn-iris-table btn-iris-edit',
              'event': 'edit'
            },
            {
              'cssClass': 'btn-iris-table btn-iris-delete',
              'event': 'delete'
            }
          ]
        }
      ] as Array<SortableTableDataConfig>,
      'tableData': [] as Array<ProductListDetailResponse>,
      'debounceGetList': null as unknown,
      'deleteProduct': {
        'confirmationTitle': deleteProductConfirmationTitle,
        'confirmationMessage': deleteProductConfirmationMessage,
        'showConfirmButton': true,
        'discardButtonText': 'Discard',
        'productId': -1
      }
    };
  },
  'computed': {
    appStateCurrentViewingDealerId (): number {
      return this.$store.state.app.currentViewingDealerId;
    }
  },
  'watch': {
    appStateCurrentViewingDealerId () {
      this.$nextTick(() => {
        this.getList();
      });
    },
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'form.warrantySealQty' (val) {
      if (val && !this.form.runNo) {
        this.getNextWarrantySealRunNo();
      }
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  mounted () {
    this.debounceGetList = debounce(this.getList, 500);

    const dealerId =
      this.$route.query && this.$route.query.dealer
        ? parseInt(this.$route.query.dealer as string)
        : this.appStateCurrentViewingDealerId;
    if (dealerId !== this.appStateCurrentViewingDealerId) {
      this.$store.commit('updateViewDealerId', dealerId);
    }
    this.$router
      .push({
        'query': {
          'dealer': dealerId + ''
        }
      })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });

    this.getList();
  },
  'methods': {
    getList () {
      let getRequest = createFilterRequest(this.pagination, this.tableFilter);
      getRequest = cleanupFilterRequest(getRequest);

      const getProductsRequest: ProductListRequest = Object.assign(
        {
          'dealerId': this.appStateCurrentViewingDealerId
        },
        getRequest
      );

      this.axios
        .get('product/list', {
          'params': getProductsRequest
        })
        .then(({ data }) => {
          const response = data as ProductListResponse;
          this.tableData = response.result.items;

          const paginationUpdate = response.result as Pagination;
          updatePagination(this.pagination, paginationUpdate);
        });
    },
    addProduct () {
      resetCreateProductRequest(this.form);
      (this.$refs.modalAddProduct as any).create();
    },
    editProduct (rowIndex: number) {
      const productId = this.tableData[rowIndex].id;

      this.axios.get(`product/${productId}`).then(({ data }) => {
        const response = data as ProductResponse;

        updateCreateProductRequest(this.form, response.result);

        (this.$refs.modalAddProduct as any).update();
      });
    },
    deleteProductFn (rowIndex: number) {
      this.deleteProduct.productId = this.tableData[rowIndex]
        ? this.tableData[rowIndex].id
        : 0;

      this.deleteProduct.confirmationTitle = deleteProductConfirmationTitle;
      this.deleteProduct.confirmationMessage = deleteProductConfirmationMessage;
      this.deleteProduct.showConfirmButton = true;
      this.deleteProduct.discardButtonText = 'Discard';
      (this.$refs.deleteProductConfirmation as any).show();
    },
    confirmDeleteProduct () {
      (this.$refs.deleteProductConfirmation as any).hide(() => {
        this.axios.delete(`product/${this.deleteProduct.productId}`).then(({ data }) => {
          this.getList();

          this.deleteProduct.confirmationTitle = 'Success';
          this.deleteProduct.confirmationMessage = data.message;
          this.deleteProduct.showConfirmButton = false;
          this.deleteProduct.discardButtonText = 'Dismiss';
        }).catch((err:AxiosError) => {
          this.deleteProduct.confirmationTitle = 'Error';
          if (err && err.response) {
            const data = err.response.data as ApiResponse;
            this.deleteProduct.confirmationMessage = data.message;
          }
          this.deleteProduct.showConfirmButton = false;
          this.deleteProduct.discardButtonText = 'Dismiss';
        }).finally(() => {
          (this.$refs.deleteProductConfirmation as any).show();
        });
      });
    },
    discardDeleteProduct () {
      (this.$refs.deleteProductConfirmation as any).hide();
    },
    submitForm () {
      this.formErrorMessage = '';
      if (this.form.productId === 0) {
        this.form.isSubmitting = true;
        this.form.dealerId = this.appStateCurrentViewingDealerId;
        const clonedForm = Object.assign({} as CreateProductRequest, this.form);

        // #region delete UI
        delete clonedForm.deliveryDateUi;
        delete clonedForm.productQtyUi;
        // #endregion

        // #region delete update
        delete clonedForm.productId;
        delete clonedForm.serialNo;
        delete clonedForm.totalSqFt;
        // #endregion

        const momentDeliveryDate = window.moment(
          this.form.deliveryDateUi,
          'DD/MM/YYYY'
        );
        clonedForm.deliveryDate = momentDeliveryDate.toISOString();
        clonedForm.warrantySealQty = clonedForm.warrantySealQty
          ? clonedForm.warrantySealQty
          : 0;
        clonedForm.runNo = clonedForm.runNo ? clonedForm.runNo : 0;
        clonedForm.products = [];
        clonedForm.products = (this.form.products as Array<ProductForm>).map((product) => {
          const p = Object.assign({}, product);
          p.totalSqFt = parseFloat(p.totalSqFt + '' ?? '0');
          return p;
        });

        this.axios
          .post('product', clonedForm)
          .then(() => {
            (this.$refs.modalAddProduct as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
            err.response.status === 422 &&
            err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddProduct as any).setErrors(result);
              }
            }
            const error = err.response.data as ApiResponse;
            this.formErrorMessage = error.message;
          })
          .finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      } else {
        this.form.isSubmitting = true;
        const clonedForm = Object.assign({}, this.form);

        // #region delete UI
        delete clonedForm.deliveryDateUi;
        delete clonedForm.productQtyUi;
        // #endregion

        // #region delete create
        delete clonedForm.dealerId;
        delete clonedForm.products;
        delete clonedForm.warrantySealQty;
        delete clonedForm.runNo;
        // #endregion

        clonedForm.totalSqFt = parseFloat(clonedForm.totalSqFt + '' ?? '0');

        const momentDeliveryDate = window.moment(
          this.form.deliveryDateUi,
          'DD/MM/YYYY'
        );
        clonedForm.deliveryDate = momentDeliveryDate.toISOString();

        this.axios
          .put('product', clonedForm)
          .then(() => {
            (this.$refs.modalAddProduct as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
            err.response.status === 422 &&
            err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddProduct as any).setErrors(result);
              }
            } else {
              const error = err.response.data as ApiResponse;
              this.formErrorMessage = error.message;
            }
          })
          .finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      }
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    },
    getNextWarrantySealRunNo () {
      interface NextRunningNoResponse{
        'nextRunNo': number,
      }

      interface ResultGetNextRunningNo extends ApiResponse{
        result: NextRunningNoResponse
      }

      this.axios.get('next-running-no').then(({ data }) => {
        const response = data as ResultGetNextRunningNo;

        this.form.runNo = response.result.nextRunNo;
      });
    }
  }
});
