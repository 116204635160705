import { Pagination } from '../Pagination';
import { FilterRequest } from './FilterRequest';
import { ApiResponse } from './Response';

// #region list
export interface ProductListRequest extends FilterRequest {
  dealerId: number
}

export interface ProductListDetailResponse {
  'id': number,
  'product.createdAt': string,
  'product.createdBy.name': string,
  'product.invoiceNo': string,
  'product.model': string,
  'product.serialNo': string,
  'product.totalSqFt': number,
  'validYears': number,
  'regSqFt': number,
  'unregSqFt': number,
}

interface ProductListResult extends Pagination {
  items: Array<ProductListDetailResponse>
}

export interface ProductListResponse extends ApiResponse {
  result: ProductListResult
}
// #endregion

// #region single
export interface ProductDetailResponse {
  'id': number,
  'deliveryDate': string,
  'invoiceNo': string,
  'model': string,
  'serialNo': string,
  'totalSqFt': number,
  'validYears': number
}

export interface ProductResponse extends ApiResponse {
  result: ProductDetailResponse
}
// #endregion

// #region create
export interface ProductForm {
  serialNo: string,
  totalSqFt: number | null,
  validYears: number|null
}

export interface CreateProductRequest {
  deliveryDate: string,
  model: string,
  invoiceNo: string,
  // #region ui
  deliveryDateUi?: string | null,
  productQtyUi?: number | null,
  // #endregion
  // #region create
  dealerId?: number,
  products?: Array<ProductForm>,
  warrantySealQty?: number | null,
  runNo?: number | null,
  // #endregion
  // #region update
  productId?: number,
  serialNo?: string,
  totalSqFt?: number,
  validYears?: number,
  // #endregion
  isSubmitting: boolean
}

export function createCreateProductRequest (): CreateProductRequest {
  return {
    'dealerId': 0,
    'deliveryDate': '',
    'deliveryDateUi': null,
    'warrantySealQty': null,
    'runNo': null,
    'model': '',
    'invoiceNo': '',
    'productQtyUi': null,
    'products': [],
    'productId': 0,
    'serialNo': '',
    'totalSqFt': 0,
    'isSubmitting': false
  };
}

export function resetCreateProductRequest (form: CreateProductRequest): void {
  form.dealerId = 0;
  form.deliveryDate = '';
  form.deliveryDateUi = null;
  form.warrantySealQty = null;
  form.runNo = null;
  form.model = '';
  form.invoiceNo = '';
  form.productQtyUi = null;
  form.products = [];
  form.productId = 0;
  form.serialNo = '';
  form.totalSqFt = 0;
}

export function updateCreateProductRequest (form: CreateProductRequest, details: ProductDetailResponse): void {
  resetCreateProductRequest(form);

  const dateInUTC = window.moment.utc(details.deliveryDate).toDate();

  form.productId = details.id;
  form.deliveryDateUi = window.moment(dateInUTC).local().format('DD/MM/YYYY');
  form.invoiceNo = details.invoiceNo;
  form.model = details.model;
  form.serialNo = details.serialNo;
  form.totalSqFt = details.totalSqFt;
  form.validYears = details.validYears;
}
// #endregion

// #region remainder-sq-ft
export interface ProductRemainderSqFt {
  id: number,
  model: string,
  serialNo: string,
  totalSqFt: number,
  invoiceNo: string,
  deliveryDate: string,
  regSqFt: number,
  unregSqFt: number
}

export interface ProductRemainerSqftList {
  products: Array<ProductRemainderSqFt>
}

export interface ProductRemainerSqftListResponse extends ApiResponse {
  result: ProductRemainerSqftList
}
// #endregion
